import React, { useEffect, useState } from "react";
import { Row, Col, Form, Typography, Card, Tooltip, Button, Input, Switch } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { manutencaoActions } from "../../actions";
import { isObjetoDiffVazio } from "../../services/funcoes";
import { ModalCliente, ModalBuscaPessoaAutorizada } from "../../components/modals/";

export default function DadosCliente({ setAbreModalAntecipacao, dadosAntecipacao, setDadosAntecipacao, possuiAntecipacao, setPossuiAntecipacao, cliente, setCliente }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [openModalBuscaCliente, setOpenModalBuscaCliente] = useState(false);
    const [openModalBuscaPessoaAutorizada, setOpenModalBuscaPessoaAutorizada] = useState(false);
    const [dadosEmail, setDadosEmail] = useState(false);
    const [dadosCliente, setDadosCliente] = useState({});

    const switchEmail = () => {
        setDadosEmail(!dadosEmail)
    };

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.cliente && isObjetoDiffVazio(manutencao.dados.cliente) && (!!!cliente.pes_id || cliente.pes_id !== manutencao.dados.cliente.pes_id)) {
            setCliente(manutencao.dados.cliente);
            setDadosAntecipacao([]);
        }
    }, [manutencao.dados]);

    function removerCliente() {
        setPossuiAntecipacao(false);
        let clienteConsumidor = {
            pes_nome: 'CONSUMIDOR FINAL',
            cpfCnpj: ''
        }
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ cliente: clienteConsumidor } } } });
        setCliente(clienteConsumidor);
        setDadosAntecipacao([]);
    }

    async function buscarDadosAntecipacao() {
        let dadosTemp = manutencao.dados.cliente;
        if (!!dadosTemp.pes_id) {
            let retorno = await api.get(`AntecipacaoClienteFornecedor/ListaAntecipacaoCliente?pessoaId=${dadosTemp.pes_id}`);
            if (!!retorno.data && retorno.data.length > 0) {
                let seq = 0;
                retorno.data.forEach((ant) => {
                    ant.key = seq;
                    seq++;
                });
                setPossuiAntecipacao(true);
                setDadosAntecipacao(retorno.data);
                setAbreModalAntecipacao(true);
            }
        }
    }

    return (
        <>
            <Card title={
                <Typography.Title level={4} className="m-b-0">
                    <img src={require("../../assets/i-info.png")} alt="Dados do Cliente" /> DADOS DO CLIENTE
                </Typography.Title>
            }>
                <Row align="middle" gutter={[8, 5]}>
                    <Col span={24}>
                        <Row align="middle" justify="space-between" gutter={[8, 0]}>
                            <Col xs={17} sm={17} md={17} lg={17} xl={17} xxl={17} className="over-text">
                                Cliente: <b>{cliente.pes_nome}</b>
                            </Col>
                            <Col className="btn-dados-cli">
                                <Tooltip title="Editar Cliente" placement="top">
                                    <Button type="text" icon={<EditOutlined />} onClick={() => {
                                        setOpenModalBuscaCliente(true);
                                    }} />
                                </Tooltip>
                                <Tooltip title="Remover Cliente" placement="top">
                                    <Button type="text" icon={<DeleteOutlined />} onClick={() => removerCliente()} />
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        CPF/CNPJ: <b>{!!cliente.pef_cpf ? cliente.pef_cpf : (!!cliente.pej_cnpj ? cliente.pej_cnpj : !!cliente.cpfCnpj ? cliente.cpfCnpj : 'Não Informado')}</b>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[8, 0]}>
                            <Col>
                                <Switch onClick={switchEmail} />
                            </Col>
                            <Col>
                                Enviar NFC-e por e-mail
                            </Col>
                        </Row>
                    </Col>
                    {!!dadosEmail === true &&
                        <Col span={24}>
                            <Form.Item className="m-b-0">
                                <Input placeholder="Informe o e-mail" />
                            </Form.Item>
                        </Col>
                    }
                    {possuiAntecipacao &&
                        <Col span={24} align="middle">
                            <Button type="primary" justify="center" onClick={() => buscarDadosAntecipacao()} className="m-t-5">
                                Possui Antecipação
                            </Button>
                        </Col>
                    }
                </Row>
            </Card>
            <ModalBuscaPessoaAutorizada exibirModalBuscaPessoaAutorizada={openModalBuscaPessoaAutorizada} fecharModalBuscaPessoaAutorizada={() => setOpenModalBuscaPessoaAutorizada(false)} dadosCliente={dadosCliente} />
            <ModalCliente exibirModalBuscaCliente={openModalBuscaCliente} fecharModalBuscaCliente={() => setOpenModalBuscaCliente(false)} setDadosCliente={setDadosCliente} dadosCliente={dadosCliente} />
        </>
    );
}