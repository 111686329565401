import React, { useEffect, useState } from 'react';
import { Layout, Row, Col, Typography, Form, Input, Checkbox, Button, notification } from 'antd';
import { UnlockFilled, MailOutlined, SendOutlined, DesktopOutlined, PhoneOutlined, EnvironmentOutlined } from '@ant-design/icons';

import "./index.less";
import api from '../../services/api';
import { SelecoesEmpresa, SlideLogin } from "./pages";
import { login, setNomeUsuario, setIdUsuario, setEmailUsuario, getIdUsuario, removerTelaAnt } from '../../services/auth';
import { parseToken } from '../../services/funcoes';

const FORMULARIO = {
    LOGIN: 1,
    SELECIONA_EMPRESA: 2
}

export default function Login() {

    const [formularioExibir, setFormularioExibir] = useState(FORMULARIO.LOGIN);
    const [carregando, setCarregando] = useState(false);
    const [formulario] = Form.useForm();
    const onFinish = values => {
        setCarregando(true);
        values.senha = btoa(values.senha);
        delete values.remember;
        api.post(`Login/EfetuarLogin`, values).then(
            res => {
                const token = res.data;
                let dadosUsuario = parseToken(res.data);
                setNomeUsuario(dadosUsuario.name);
                if (parseInt(getIdUsuario()) !== parseInt(dadosUsuario.UsuarioID)) {
                    removerTelaAnt()
                }
                setIdUsuario(dadosUsuario.UsuarioID);
                setEmailUsuario(dadosUsuario.email);
                login(token);
                setFormularioExibir(FORMULARIO.SELECIONA_EMPRESA);
            }
        ).catch(
            (erro) => {
                if (!!!erro) {
                    if (!!erro.response.data) {
                        notification.error({ message: erro.response.data || 'Falha ao efetuar o login' });
                    } else {
                        notification.error({ message: 'Falha ao efetuar o login', description: 'Verifique os dados informados e tente novamente.' });
                    }
                } else {
                    notification.error({ message: 'Falha ao efetuar o login', description: 'Verifique os dados informados e tente novamente.' });
                }
            }
        ).finally(() => {
            setCarregando(false);
        })
    };

    useEffect(() => {
        localStorage.removeItem("@GestorWeb-Token");
    }, [])

    return (
        <Layout className="vh100">
            <Layout.Content>
                <Row justify="center" className="vh100">
                    <Col className="vh100 fixed-left">
                        <SlideLogin />
                    </Col>
                    <Col className="vh100 bg-log fixed-right">
                        <Row align="middle" justify="center" className="vh100" gutter={[16, 16]}>
                            <Col span={18}>
                                <Row align="middle" justify="center" gutter={[0, 16]}>
                                    <Col>
                                        <img src={require("../../assets/logo.png")} alt="Gestor Web" width="250" height="73" />
                                    </Col>
                                    <Col span={24}>
                                        <Typography.Text>
                                            Identifique abaixo suas credenciais para realizar o acesso. <br /><br />
                                        </Typography.Text>
                                    </Col>
                                </Row>
                                {formularioExibir === FORMULARIO.LOGIN &&
                                    <Form layout="vertical" name="login" initialValues={{ remember: true }} size="middle" onFinish={onFinish}>
                                        <Row gutter={[0, 8]}>
                                            <Col span={24}>
                                                <Form.Item name="email" rules={[{ required: true, message: 'Informe seu e-mail para realizar o login.' }]}>
                                                    <Input placeholder="Informe seu e-mail" prefix={<MailOutlined />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="senha" rules={[{ required: true, message: 'Informe sua senha de acesso.' }]}>
                                                    <Input.Password placeholder="Informe sua senha" prefix={<UnlockFilled />} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Row align="middle" justify="space-between" gutter={[8, 16]}>
                                                    <Col>
                                                        <Form.Item name="remember" valuePropName="checked">
                                                            <Checkbox>Salvar meus dados de login</Checkbox>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item>
                                                            <Button type="primary" htmlType="submit" loading={carregando} size="large">
                                                                Acessar <SendOutlined />
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {
                                                /*
                                                    <Col span={24}>
                                                        <Row align="middle" justify="center" gutter={[8, 0]}>
                                                            <Col>
                                                                <Button size="small" type="primary" onClick={() => setFormularioExibir(FORMULARIO.ESQUECI_SENHA)}>
                                                                    Esqueci minha senha <UnlockFilled />
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col> 
                                                */
                                            }
                                        </Row>
                                    </Form>
                                }
                                {formularioExibir === FORMULARIO.SELECIONA_EMPRESA && <SelecoesEmpresa />}
                                {
                                    //formularioExibir === FORMULARIO.ESQUECI_SENHA && <EsqueciSenha formulario={formulario} onAcessar={() => setFormularioExibir(FORMULARIO.LOGIN)} />
                                }
                            </Col>
                        </Row>
                        <Row align="middle" justify="center" className="rodape-login-copyright">
                            <Col>
                                <Row align="middle" justify="center" gutter={[8, 0]}>
                                    <Col>
                                        <DesktopOutlined />
                                        <Typography.Link href="https://www.gestorweb.com.br/" target="_blank" underline> www.gestorweb.com.br</Typography.Link> | <PhoneOutlined /> (55) 3535-4900
                                    </Col>
                                    <Col>
                                        | <EnvironmentOutlined /> Três de Maio - RS - 98910-000
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} align="center">
                                Todos os direitos reservados - Gestor Web ®
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
}